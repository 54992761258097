@font-face {
	font-family: "Inter-Regular";
	src: local("Inter-Regular"),
		url("./assets/fonts/Inter-Regular.ttf") format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: "Inter-SemiBold";
	src: local("Inter-SemiBold"),
		url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
	font-family: "Inter-Medium";
	src: local("Inter-Medium"),
		url("./assets/fonts/Inter-Medium.ttf") format("truetype");
}
@font-face {
	font-family: "Inter-Bold";
	src: local("Inter-Bold"),
		url("./assets/fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "Inter-Black";
	src: local("Inter-Black"),
		url("./assets/fonts/Inter-Black.ttf") format("truetype");
}

a {
	text-decoration: none;
}

a:hover {
	color: rgba(160, 170, 184, 1);
}

.label-error {
	font-size: 12px;
	color: rgba(204, 25, 100, 1);
	font-family: "Inter-Regular";
	font-style: normal;
	font-weight: 600;
	margin-top: 8px;
	margin-bottom: 20px;
}

input::-webkit-input-placeholder {
	font-size: 14px;
	color: rgba(160, 170, 184, 1);
	font-family: "Inter-Medium";
	font-weight: 500;
}
.pass-wrapper {
	position: relative;
	display: flex;
}
.input-wrapper {
	position: relative;
	display: flex;
}
.pass-wrapper i {
	position: absolute;
	top: 23px;
	right: 15px;
	color: rgba(41, 42, 46, 1);
}
.pass-wrapper i:hover {
	color: rgba(160, 170, 184, 1);
	cursor: pointer;
}

/* input styling */
input:focus,
textarea:focus,
select:focus {
	outline: none;
}
.error-outline {
	border: solid 1px red !important;
}
.btn-primary:disabled,
.btn-primary[disabled] {
	background-color: #e0e5ed;
	color: #a0aab8;
	cursor: not-allowed;
}
button {
	transition: all 0.2s linear;
	border: none;
}
i,
a {
	transition: all 0.2s linear;
}

/* Interupt Css Sidebar */
.simplebar-mask,
.simplebar-offset,
.simplebar-content-wrapper {
	height: 100vh !important;
}
.simplebar-placeholder {
	display: none !important;
}
.css-claira {
	margin-left: 0 !important;
	margin-right: 10px !important;
}
.modalBodyC {
	height: "80vh" !important;
	overflow-y: "auto" !important;
}
.MuiOutlinedInput-input {
	height: 1em !important;
}

/* Pagination Custom */
.MuiTablePagination-actions > .MuiIconButton-root {
	border: 1px solid #767e8c !important;
	border-radius: 6px;
	padding: 9px 12px;
	margin-left: 12px;
	background-color: #ffffff !important;
}
.MuiTablePagination-toolbar {
	background-color: rgba(243, 245, 249, 0.5);
}

/* Custom Tab */
.MuiButtonBase-root {
	font-size: 14px !important;
}
.MuiTableHead-root {
	background: rgba(243, 245, 249, 0.5);
}

.MuiTablePagination-toolbar {
	padding-right: 30px !important;
	min-height: 66px !important;
	justify-content: space-between;
}
.MuiTablePagination-displayedRow {
	flex: 3;
}

.MuiTablePagination-actions {
	flex: 3;
	text-align: right;
}
.MuiTablePagination-spacer {
	display: none !important;
}

.MuiTab-root {
	text-transform: capitalize !important;
	font-size: 14px !important;
	color: #767e8c !important;
	font-family: "Inter-Medium" !important;
	font-weight: 500 !important;
	line-height: 20px !important;
}
.Mui-selected {
	color: #292a2e !important;
}
.MuiMenuItem-root {
	min-height: 45px !important;
}

.dropzone-cus {
	text-align: center;
	color: #a0aab8;
	font-size: 12px;
	font-family: "Inter-Medium" !important;
	width: 100%;
	height: 100%;
	padding: 30px 40px;
	background: rgba(224, 229, 237, 0.25);
	/* Neutral/Ghost Grey */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: 1px dashed #a0aab8;
	box-sizing: border-box;
	margin-top: 10px;
	border-radius: 10px;
}

/* .MuiSelect-select {
  line-height:1em !important;
} */

.datepicker-cus {
	margin-top: 10px;
	padding: 10px 20px;
	/* Neutral/White */

	background: #ffffff;
	/* Neutral/Ghost Grey */

	border: 1px solid #a0aab8;
	box-sizing: border-box;
	border-radius: 5px;
	width: 100% !important;
}

.schedule-cus {
	display: flex;
	flex-direction: row;
	padding-top: 10px;
}

.date-schedule-cus {
	padding-right: 20px;
}
.postReview {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.postReviewAdd {
	transition: all 0.3s ease-out;
	background: #ffffff;
	position: fixed;
	z-index: 99999;
	bottom: 20px;
	right: 10px;
	box-shadow: 0px 9px 19px -10px rgba(0, 0, 0, 0.47);
}
.postReviewAddBack {
	transition: all 0.3s ease-out;
	background: #ffffff;
	position: fixed;
	z-index: -99999;
	bottom: 20px;
	right: 10px;
	box-shadow: 0px 9px 19px -10px rgba(0, 0, 0, 0.47);
}
.showPreviewAdd {
	background: #ffffff;
	position: fixed;
	z-index: 99999;
	bottom: 10px;
	right: 10px;
	box-shadow: 0px 9px 19px -10px rgba(0, 0, 0, 0.47);
}
body {
	font-family: "Inter-Regular";
}
.txtPost {
	width: 70% !important;
	font-family: "Inter-Regular" !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	text-align: center !important;
	font-weight: 500 !important;
	font-size: 24px !important;
	color: rgba(255, 255, 255, 1) !important;
}

.form-inline {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.form-inline input {
	vertical-align: middle;
	padding: 13px 15px;
	width: 100%;
	background-color: #fff;
	border: 1px solid #ddd;
	margin-bottom: 10px;
	border-radius: 5px;
}

.listAnswer {
	display: inline-flex !important;
	flex-direction: column;
	width: 100%;
}

.listAnswer li {
	padding: 18px 16px;
	background: #f3f5f9;
	border-radius: 8px;
	font-family: "Inter-Regular";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 10px;
}
.btn-action-filter-light {
	height: 43px;
	font-weight: 600 !important;
	font-family: "Inter-Regular" !important;
	text-transform: capitalize !important;
	padding: 14px 20px !important;
	font-size: 14px !important;
	margin-right: 10px !important;
	color: black !important;
	background-color: white !important;
	border: solid 1px black;
	border-radius: 5px;
}

.btn-action-filter-dark {
	width: 160px;
	height: 43px;
	font-weight: 600 !important;
	font-family: "Inter-Regular" !important;
	text-transform: capitalize !important;
	padding: 14px 20px !important;
	font-size: 14px !important;
	margin-right: "10px" !important;
	color: white !important;
	background-color: black !important;
	border-radius: 5px;
}

.btn-action-filter-dark-w-250 {
	width: 250px;
	height: 43px;
	font-weight: 600 !important;
	font-family: "Inter-Regular" !important;
	text-transform: capitalize !important;
	padding: 14px 20px !important;
	font-size: 14px !important;
	margin-right: "10px" !important;
	color: white !important;
	background-color: black !important;
	border-radius: 5px;
}
.MuiListItemText-primary {
	font-size: 14px !important;
}

.btn-action-filter-light {
	width: 160px;
	height: 43px;
	font-weight: 600 !important;
	font-family: "Inter-Regular" !important;
	text-transform: capitalize !important;
	padding: 14px 20px !important;
	font-size: 14px !important;
	margin-right: "10px" !important;
	color: black !important;
	border: solid 1px black !important;
	border-radius: 5px;
}

.btn-action-filter-dark:hover {
	background-color: #424242 !important;
}
.btn-action-filter-light:hover {
	background-color: #ededed !important;
}

#captureImgEdit,
#captureImg,
#thumbsEdit {
	background-position: center center !important;
	background-size: cover !important;
	width: 394px;
	height: 240px;
	display: flex;
	align-items: center;
	justify-content: center;
}
/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #bfbfbf;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #cecece;
}
table {
	min-width: 700px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.leftDiagram,
.rightDiagram {
	width: 50%;
}

input[type="text"]:disabled {
	background-color: #F3F5F9;
	border: solid 1px #A0AAB8;
}

.no-disabled-style input[type="text"]:disabled {
	background-color: white !important;
	border: none !important;
}

textarea:disabled {
	background-color: #fff7f7 !important;
	border: solid 1px #f4f4f4;
}
