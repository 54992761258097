.preview-modal {
	font-family: "Inter-Regular";
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	font-size: 18px;
}

.preview-modal a {
	color: blue;
	text-decoration: underline;
}

.preview-modal p {
	margin-top: 12px;
	margin-bottom: 12px;
}

.preview-modal ul {
	list-style-type: disc;
}

.preview-modal ol {
	list-style-type: decimal;
}

.preview-modal ul li {
	margin-left: 16px;
}

.preview-modal ol li {
	margin-left: 20px;
}

.preview-modal em {
	font-style: italic;
}

.preview-modal strong {
	font-weight: bold;
}

.preview-modal s {
	text-decoration: line-through;
}

.preview-modal h1,
.preview-modal h2,
.preview-modal h3 {
	margin-top: 24px;
	margin-bottom: 24px;
	font-weight: 700;
}

.preview-modal h4,
.preview-modal h5,
.preview-modal h6,
.preview-modal h7 {
	margin-top: 16px;
	margin-bottom: 16px;
	font-size: 18px;
}

.preview-modal h1 {
	font-weight: 800;
	font-size: 24px;
}

.preview-modal h2 {
	font-size: 22px;
}

.preview-modal h3 {
	font-size: 20px;
}
