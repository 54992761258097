.side-menu {
	position: fixed;
	background: #ffffff;
	width: 280px;
	height: 100%;
	box-sizing: border-box;
	padding: 13px;
	transition: width 0.2s ease-in;
	font-family: "Inter-Medium" !important;
	font-weight: 500;
	border-right: solid 1px #e0e5ed;
}

.root-style.inactive {
	width: 80px;
}

.side-menu.inactive {
	width: 80px;
}

.side-menu .top-section {
	position: relative;
}

.side-menu .top-section .logo {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.side-menu .top-section .logo img {
	max-width: 100%;
	max-height: 100%;
}

.side-menu .top-section .toggle-menu-btn {
	color: #666;
	font-size: 20px;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	cursor: pointer;
}

.side-menu.inactive .top-section .toggle-menu-btn {
	right: -50px;
}

.side-menu .search-controller {
	color: #fff;
	position: relative;
}

.side-menu .search-controller .search-btn {
	width: 40px;
	height: 35px;
	position: absolute;
	background: transparent;
	border: 0;
	font-size: 20px;
	color: #666;
}

.side-menu .search-controller input[type="text"] {
	border: 0;
	outline: none;
	height: 35px;
	background: #333;
	border-radius: 5px;
	display: block;
	margin: 20px 0;
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
	color: #666;
}

.side-menu.inactive .main-menu .menu-item {
	border: none;
}

.side-menu .main-menu .menu-item {
	color: #767e8c;
	text-decoration: none;
	font-size: 14px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding: 10px 10px;
	border-radius: 5px;
	/* border-bottom: solid 1px #E0E5ED; */
}

.side-menu .main-menu .menu-item.active {
	background: #f3f5f9 !important;
	border-radius: 5px;
	color: #292a2e;
	font-weight: 500;
	border-bottom: none;
}

.menu-item.active .icon-menu-item.active {
	display: none !important;
}

.menu-item.active .icon-menu-item.inactive {
	display: block !important;
}

.side-menu .main-menu {
	position: relative;
	max-height: 100vh;
	overflow-y: hidden;
	overflow-x: hidden;
}
/* not required any more */
.side-menu.inactive .icon-menu-item.active {
	display: none !important;
}

.side-menu.inactive .icon-menu-item.inactive {
	display: none !important;
}

.side-menu.inactive .main-menu .menu-item .menu-name {
	display: none;
}

.side-menu .main-menu .menu-item .menu-name {
	flex: 1;
}

.side-menu .main-menu .menu-item .menu-icon {
	align-items: center;
	justify-content: center;
	display: flex;
	width: 40px;
	height: 40px;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
}

.side-menu .main-menu .menu-item span {
	position: absolute;
	display: inline-block;
	line-height: 40px;
	opacity: 1;
	transition: opacity 0.2s ease-in;
}

.side-menu.inactive .main-menu .menu-item span {
	opacity: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}

.side-menu .main-menu .sub-menu li {
	padding-left: 35px;
}

.side-menu .main-menu .sub-menu {
	color: #292a2e;
	box-sizing: border-box;
	/* padding-left: 35px; */
	max-height: 0;
	overflow: hidden;
	font-weight: 500;
	transition: max-height 0.2s ease-in;
}

.side-menu .main-menu .sub-menu.active {
	max-height: 400px;
}

.side-menu .main-menu .sub-menu a {
	display: block;
	margin: 5px 0;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
	color: #767e8c;
	box-sizing: border-box;
	padding: 14px;
}

.side-menu .main-menu .sub-menu a.active {
	/* change in background, not recorded in video */
	/* display: block;
    /* border-radius: 5px; */
	color: #292a2e;
	font-weight: 500;
}

.side-menu-footer {
	border-top: solid 1px #e0e5ed;
	border-right: solid 1px #e0e5ed;
	align-items: center;
	width: 280px;
	background: #ffffff;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
}

.side-menu-footer .avatar {
	width: 40px;
	height: 40px;
	overflow: hidden;
	display: inline-block;
}
.side-menu-footer .avatar img {
	max-width: 100%;
}

.side-menu-footer .user-info {
	margin: 0 10px;
	color: #292a2e;
	opacity: 1;
	flex: 1;
	transition: opacity 0.2s ease-in;
}

.side-menu.inactive .side-menu-footer {
	width: 80px;
	padding: 10px;
	text-align: center;
	border-right: solid 1px #e0e5ed;
}

.side-menu.inactive .side-menu-footer .avatar {
	width: 80px;
	border-radius: 0;
}

.side-menu.inactive .side-menu-footer .user-info {
	/* opacity: 1;
    width: 0;
    height: 0;
    overflow: hidden; */
	display: none;
}

.side-menu.inactive .side-menu-footer .user-action {
	/* opacity: 1;
    width: 0;
    height: 0;
    overflow: hidden; */
	display: none;
}

.side-menu-footer .user-info h5 {
	font-size: 15px;
}

.side-menu-footer .user-info p {
	font-size: 14px;
	color: #767e8c;
}

.divider {
	width: 100%;
	height: 1px;
	border-radius: 1px;
	background: #333;
}

.container {
	margin-left: 280px;
	transition: margin-left 0.2s ease-in;
	padding: 50px;
	box-sizing: border-box;
}

.container.inactive {
	margin-left: 80px;
}
